<template>
  <div>
    <p>{{ $t('onboarding.bankAccountInformation.title') }}</p>
    <p>{{ $t('onboarding.bankAccountInformation.desc') }}</p>
    <v-form
      v-model="isFormValid"
      @submit.prevent="submit"
    >
      <!-- BANK ACCOUNT OWNER -->
      <v-text-field
        id="bank-account-owner"
        type="text"
        :rules="rules.fullname"
        :value="data.financialData.refBankAccOwner"
        required
        :label="`${$t('onboarding.bankAccountInformation.fields.refBankAccOwner')} *`"
        outlined
        disabled
      ></v-text-field>

      <!-- BANK ACCOUNT IBAN -->
      <v-text-field
        class="uppercase"
        id="bank-account-iban"
        type="text"
        :rules="this.validIban ? rules.iban : [$t('validators.iban.invalid')]"
        v-model="data.financialData.refBankIban"
        v-mask="'AA## #### #### #### #### ####'"
        :label="`${$t('onboarding.bankAccountInformation.fields.refBankIban')} *`"
        outlined
        required
        @change="getOpeniban"
        validate-on-blur
      ></v-text-field>

      <div v-if="this.validIban">
        <!-- BANK ACCOUNT NAME - only for informational purposes-->
        <v-text-field
          id="bank-account-bank"
          type="text"
          v-model="openiban.name"
          :label="`${$t('onboarding.bankAccountInformation.fields.refBankName')} *`"
          outlined
          disabled
        ></v-text-field>

        <!-- BANK ACCOUNT BIC -->
        <v-text-field
          id="bank-account-bic"
          type="text"
          :rules="rules.bic"
          v-model="data.financialData.refBankBic"
          :label="`${$t('onboarding.bankAccountInformation.fields.refBankBic')} *`"
          outlined
          disabled
        ></v-text-field>
      </div>
    </v-form>
  </div>
</template>

<script>
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import { mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import { mapFields } from 'vuex-map-fields';
import clone from '@/utils/clone';
import { fullname, iban, bic } from '@/utils/validators';
import { datadogLogs } from '@datadog/browser-logs';

export default {
  name: 'BankAccountInformation',
  directives: {
    mask,
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      rules: {
        fullname,
        iban,
        bic,
      },
      validIban: false,
      openiban: null,
    };
  },
  computed: {
    ...mapFields('onboarding', [
      'isFormValid',
    ]),
    ...mapGetters('onboarding', [
      'editMode',
    ]),
  },
  watch: {
    openiban(v) {
      if (v) {
        this.data.financialData.refBankBic = v.bic;
      } else {
        this.data.financialData.refBankBic = null;
      }
    },
  },
  created() {
    const { firstName, lastName } = this.data.personalData;
    this.data.financialData.refBankAccOwner = `${firstName} ${lastName}`;
    this.getOpeniban();
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'updateOnboarding',
    ]),
    async submit() {
      const input = {
        investor: {
          personal: {
            bankAccountInformation: {
              refBankIban: this.data.financialData.refBankIban,
              refBankBic: this.data.financialData.refBankBic,
              refBankAccOwner: this.data.financialData.refBankAccOwner,
            },
          },
        },
        mainStage: 'personal',
        subStage: 'bankAccountInformation',
      };
      try {
        await this.updateOnboarding({
          input,
          newData: this.data,
        });
        if (this.editMode) {
          this.$store.dispatch('onboarding/editMode', null);
          this.$router.push({
            name: 'Onboarding.Personal',
            params: {
              step: 'Overview',
            },
          });
        } else {
          this.setSubStage('sourceofwealthinformation');
        }
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
    getOpeniban() {
      if (this.data.financialData.refBankIban && this.data.financialData.refBankIban.length > 0) {
        const config = {
          url: `https://api.ibanapi.com/v1/validate/${this.data.financialData.refBankIban}?api_key=${process.env.VUE_APP_IBANAPI_API_KEY}`,
          method: 'post',
          adapter: jsonpAdapter,
        };
        axios.request(config)
          .then((response) => {
            if (response.data.message === 'Valid IBAN Number') {
              if (response.data.data.bank.bank_name) {
                this.validIban = true;
                this.openiban = {
                  name: response.data.data.bank.bank_name,
                  bic: response.data.data.bank.bic,
                };
              } else {
                datadogLogs.logger.warn(
                  'IBAN was reported valid but no bank name found', { iban: this.data.financialData.refBankIban },
                );
              }
            } else {
              this.resetOpeniban();
            }
          })
          .catch((error) => {
            this.resetOpeniban();
            this.$notify.error(error);
          });
      } else {
        this.resetOpeniban();
      }
    },
    resetOpeniban() {
      this.validIban = false;
      this.openiban = null;
    },
  },
};
</script>

<style>
.uppercase input {
  text-transform: uppercase;
}
</style>
